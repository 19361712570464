import React from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

export default function FARbtn({ to }) {
  return (
    <Wrapper>
      <NavLink to={to}>
          <button className='far-btn'>
              View more
          </button>
      </NavLink>
    </Wrapper>
  )
}

const Wrapper = styled.div`
button {
  border: 1px solid white;
  padding: 7px 15px;
  transition: 0.5s;
  background-color: transparent;
  margin-top: 10px;
  color: var(--whiteColor);
  &:hover {
    color: var(--secondaryColor);
    background-color: white;
  }
}
`;