import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";

export default function HeroBanner() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile screens at 768px or less
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Add listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []);

  return (
    <Wrapper>
      <div className="heroSec">
        <Carousel fade>
          {[
            { imageSrc: "/images/homeBanners/homeBnr1.jpg", caption: <Caption1 /> },
            { imageSrc: "/images/homeBanners/homeBnr2.jpg", caption: <Caption2 /> },
            { imageSrc: "/images/homeBanners/homeBnr3.jpg", caption: <Caption3 /> },
            { imageSrc: "/images/homeBanners/homeBnr4.jpg", caption: <Caption4 /> },
          ].map((item, index) => (
            <Carousel.Item key={index}>
              <div
                style={{
                  background: `url("${item.imageSrc}")`,
                  backgroundPosition: `${isMobile ? "center" : "right top"}`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat"
                }}
                className="carouselBox"
              ></div>
              <Carousel.Caption>{item.caption}</Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Wrapper>
  );
}

const Caption1 = () => {
  return (
    <div className="d-flex">
      <div className="sideSpaceBox"></div>
      <div className="slide-info">
        <h1 className="heading-1">
          <span className="primaryColor"> WE BOTH ARE PROTECTED AGAINST RABIES </span>
        </h1>
        <div className="d-flex justify-content-between align-items-center p-0">
          <h2 className="heading-2 whiteColor"> ARE YOU TOO? </h2>
          <button className="lm bgSecondary btn-lg"> Learn More </button>
        </div>
      </div>
    </div>
  );
};

const Caption2 = () => {
  return (
    <div className="d-flex">
      <div className="sideSpaceBox"></div>
      <div className="slide-info">
        <h1 className="heading-2"> 
          <span className="whiteColor"> LET YOUR PET LOVE BE FEARLESS </span> 
        </h1>
        <h2 className="heading-1 primaryColor">PROTECT YOURSELF</h2>
      </div>
    </div>
  );
};

const Caption3 = () => {
  return (
    <div className="d-flex">
      <div className="sideSpaceBox"></div>
      <div className="slide-info">
        <h1 className="heading-2">
          <span className="whiteColor"> LET YOUR CHILD BE FEARLESS </span>  
        </h1>
        <h2 className="heading-1 primaryColor"> PROTECT FROM RABIES </h2>
      </div>
    </div>
  );
};

const Caption4 = () => {
  return (
    <div className="d-flex ">
      <div className="sideSpaceBox"></div>
      <div className="slide-info">
        <h1 className="heading-2"> 
          <span className="whiteColor"> LET YOUR MORNING RUN BE FEARLESS </span>
        </h1>
        <h2 className="heading-1 primaryColor"> PROTECT YOURSELF FROM RABIES </h2>
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  margin-top: -138px;
  z-index: 0;

  .heroSec {
    margin-bottom: 65px;
    .carousel{
      height: 100vh;
      .carousel-caption{
        left: 0%;
        right: 12%;
      }
    }
    .carouselBox{
      height: 100vh;
      width: 100%;
      ${'' /* background-size: cover;
      background-repeat: no-repeat;
      background-position: top right; */}
    }
    .sideSpaceBox{
      width: 65%;
    }
    .slide-info {
      width: 35%;
      ${'' /* height: 75vh;
      display: flex;
      flex-direction: column;
      justify-content: center; */}
      text-align: start;
      padding-bottom: 5%;
    }
    .heading-2 {
      text-shadow: 2px 5px 5px #000;
      font-family: inherit;
      line-height: 1.3em;
      font-size: 28px;
      font-weight: 800;
    }
    .heading-1 {
      font-size: 56px;
      line-height: 56px;
      text-shadow: 2px 5px 5px rgba(0,0,0,0.8);
      font-size: 64px;
    }
    .carousel-indicators {
      gap: 5px;
      button {
        background-color: #fff;
        border: #1d1d1d;
        gap: 10px;
        padding: 2px;
        width: 20px;
        border-radius: 5px;
      }
      .active {
        background-color: var(--secondaryColor);
      }
    }
    span.carousel-control-next-icon, span.carousel-control-prev-icon {
      color: var(--secondaryColor);
    }

    .lm {
      padding: 10px 28px;
      background-color: var(--secondaryColor);
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
    }
    .lm:hover {
      background-color: var(--primaryColor);
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px;
    .heroSec{
      margin-bottom: 30px;
      .carousel{
        height: 40vh;
      }
      .carouselBox{
        height: 40vh;
        width: 100%;
        ${'' /* background-size: cover;
        background-repeat: no-repeat;
        background-position: center; */}
      }
    }

    .sideSpaceBox{
      width: 40% !important;
    }
    .slide-info {
      width: 60% !important;
      padding-bottom: 0% !important;
    }

    .heading-1 {
      font-size: 28px !important;
      line-height: 1.2em !important;
    }
    .heading-2 {
      line-height: 0.5rem;
      font-size: 18px !important;
      font-weight: 800;
    }
    .lm {
      margin: 0 auto !important;
    }
  }
`;
