import React from "react";
import styled from "styled-components";

export default function Section3() {
  return (
    <Wrapper className="">
      <div className="container mx-auto p-md-5 p-3 py-5 my-5">
        <div className="m-auto d-md-flex mb-4">
          <div className="pe-md-5" style={{borderRight: "1px solid #e1e1e1"}}>
            <h2 className="heading-2 mb-3 primaryColor ps-4" style={{borderLeft: "8px solid var(--primaryColor)"}}>
              CSR Initiative: Rabies Free Thiruvananthapuram
            </h2>
          </div>
          <div className="d-flex-cc ps-md-5">
            <p className="textAll textColor">
              The ‘rabies free Thiruvananthapuram’ project is being implemented
              with the assistance of National Dairy Development Board and its
              subsidiary Indian Immunologicals Ltd (IIL). This is being
              implemented by the Compassion for Animals Welfare Association
              (CAWA). IIL funds this project through it’s CSR initiative.
            </p>
          </div>
        </div>
        <div className="bg-white p-md-5 p-3 rounded-4 boxShadow">
          <StyledIframe
            width="100%"
            height="515"
            className="rounded-4"
            src="https://www.youtube.com/embed/PAZqjN9Thx0?si=KEZZwRepXqYC8UaH"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></StyledIframe>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
background-image: linear-gradient(to bottom, #E9F7FE 50%, transparent 50%);
@media (max-width: 768px) {
  background-image: linear-gradient(to bottom, #E9F7FE 75%, transparent 25%);
}
`;

const StyledIframe = styled.iframe`
  @media (max-width: 768px) {
    height: 280px;
  }
`;