import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from "styled-components";
import MobileNumberOTPForm from '../components/MobileNumberOTPForm';
import RVSbtnPopup from '../utils/RVSbtnPopup';
import { useNavigate } from 'react-router-dom';

function VaccineSchedulesReminder() {
    const { first_dose_date } = useParams();
    const [selectedDate, setSelectedDate] = useState('');
    const [scheduleDates, setScheduleDates] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        if (first_dose_date) {
            setSelectedDate(first_dose_date);
        }

        const firstDoseDate = new Date(selectedDate);

        const secondDoseDate = new Date(firstDoseDate);
        secondDoseDate.setDate(firstDoseDate.getDate() + 3);

        const thirdDoseDate = new Date(firstDoseDate);
        thirdDoseDate.setDate(firstDoseDate.getDate() + 7);

        const fourthDoseDate = new Date(firstDoseDate);
        fourthDoseDate.setDate(firstDoseDate.getDate() + 14);

        const fifthDoseDate = new Date(firstDoseDate);
        fifthDoseDate.setDate(firstDoseDate.getDate() + 27);

        setScheduleDates({
            firstDoseDate: formatDate(firstDoseDate),
            secondDoseDate: formatDate(secondDoseDate),
            thirdDoseDate: formatDate(thirdDoseDate),
            fourthDoseDate: formatDate(fourthDoseDate),
            fifthDoseDate: formatDate(fifthDoseDate),
        });
    }, [first_dose_date, selectedDate]);

    return (
        <Wrapper>
            <div className="container mt-md-4">
                <div className="rounded-4 row p-md-0 p-4">
                    <div className='col-md-6 py-md-5 pe-md-5 p-0'>
                        <h2 className='heading-3 mb-0 secondaryColor'> Your 5 Doses Schedule </h2>
                        <p className='pe-md-5'> Based on 1st dose date your remaining 4 doses dates are scheduled as below, You'll get notified for your next dose 1 day before </p>
                        <div className='d-flex flex-column gap-1 datesWrpr'>
                            {
                                scheduleDates !== null &&
                                [
                                    { title: "st", date: scheduleDates.firstDoseDate },
                                    { title: "nd", date: scheduleDates.secondDoseDate },
                                    { title: "rd", date: scheduleDates.thirdDoseDate },
                                    { title: "th", date: scheduleDates.fourthDoseDate },
                                    { title: "th", date: scheduleDates.fifthDoseDate }
                                ].map((item, idx) => (
                                    <div key={idx} className="timeBox border">
                                        <p className='mb-0'> <CalendarIcon /> <span> {idx + 1}<sup>{item.title}</sup> Dose </span> <span className="fw-bold ps-2"> {item.date} </span> </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='col-md-6 p-md-5 p-3 bg-white shadow-sm rounded-4'>
                        <h2 className='heading-3 mb-0 secondaryColor'> Name and Mobile number </h2>
                        <p> You will be notified for your doses before 1 day </p>
                        <MobileNumberOTPForm firstDoseDate={selectedDate} setScheduleDates={setScheduleDates} setShowPopup={setShowPopup} />
                    </div>
                </div>
            </div>

            <RVSbtnPopup
                show={showPopup}
                selectedDate={selectedDate}
                scheduleDates={scheduleDates}
                onHide={() => {
                    setShowPopup(false);
                    setTimeout(() => { navigate('/') }, 1000);
                }}
            />
        </Wrapper>
    )
}

export default VaccineSchedulesReminder;

const CalendarIcon = () => {
    return (
        <div className='CalIconBox bg-light text-white rounded d-inline'>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32" fill="none">
                <path d="M14.7434 24.7576H17.2421M22.2542 24.7576H24.753M7.24697 24.7576H9.74575M14.7434 17.2612H17.2421M22.2542 17.2612H24.753M7.24697 17.2612H9.74575M1 10.993H31M23.5036 7.24479V0.99782M8.49639 7.24479V0.99782M6.01204 30.9834H25.988C28.756 30.9834 31 28.7394 31 25.9713V8.5087C31 5.74062 28.756 3.4966 25.988 3.4966H6.01204C3.24396 3.4966 1 5.74062 1 8.5087V25.9713C1 28.7394 3.24396 30.9834 6.01204 30.9834Z" stroke="#b72133" stroke-width="2.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    )
}

const Wrapper = styled.div`
.timeBox{
    width: 220px;
    padding: 5px 10px 5px 0px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}
.CalIconBox{
    padding: 8px 10px 8px 8px;
    width: 35px;
    height: 35px;
    margin-right: 8px;
    svg{
        overflow: visible;
    }
}
`