import React, { useState } from 'react';
import styled from "styled-components";
import DoctorsRegisterForm from './DoctorsRegisterForm';
import { Nav } from 'react-bootstrap';
import DoctorRegSuccessPopup from '../utils/DoctorRegSuccessPopup';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const [showPopup, setShowPopup] = useState(false);
  const [regId, setregId] = useState(null);
  const navigate = useNavigate();

  return (
    <Wrapper className='p-4'>
      <div className="pageBanner"></div>

      <div className="container drRegWrpr">
        <div className="row rounded-4 overflow-hidden boxShadow">
          <div className='loginBoxWrpr-sideSection col-md-6 p-0 d-flex-cc'>
            <div></div>
          </div>
          <div className='col-md-6 p-md-5 p-4 bg-white'>
            <h2 className="heading-2 mb-0 secondaryColor text-center"> Register </h2>
            <DoctorsRegisterForm setregId={setregId} setShowPopup={setShowPopup} />
            <Nav.Link href="/login" className='mt-4 text-center'> Already a registered? <span className='primaryColor'> Login </span> </Nav.Link>
          </div>
        </div>
      </div>

      <DoctorRegSuccessPopup
        show={showPopup}
        regId={regId}
        onHide={() => {
            setShowPopup(false);
            setTimeout(() => { navigate('/rabies-and-doctor'); }, 700);
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.section`
.pageBanner {
  height: 35vh;
}

.drRegWrpr{
  width: 900px;
  .loginBoxWrpr-sideSection{
    height: auto;
    background-image: url('/images/loginSideImg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media (max-width: 768px) {
  .pageBanner {
    height: 0;
    background-position: left bottom;
  }
  .drRegWrpr{
    width: auto;
    .loginBoxWrpr-sideSection{
      height: 250px;
    }
  }
}
`
