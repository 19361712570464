import React from "react";
import styled from "styled-components";
import ContactUsForm from "./ContactUsForm ";

function ContactUsFormWrpr({ doctorDetails }) {
  return (
    <Wrapper className="row">
      <div className="col-md-7">
        <div className="row rounded-4 bg-white w-100">
          <h2 className="mb-3 ms-4">Contact Us</h2>
          <ContactUsForm doctorDetails={doctorDetails} />
        </div>
      </div>
      <div className="col-md-5">
        <img src="/images/mask/doctor_dog.jpg" alt="docdog" className="img-fluid" />
      </div>
    </Wrapper>
  );
}

export default ContactUsFormWrpr;

const Wrapper = styled.section`
img{
  mask-image: src("/images/mask/pawprint.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  width: 100%;
}
`;
