import React from 'react';
import styled from "styled-components";
import DoctorsOTPloginForm from './DoctorsOTPloginForm';
import { Nav } from 'react-bootstrap';

export default function Login({ setAuth, setDoctorId }) {
  return (
    <Wrapper className='p-4'>
      <div className="pageBanner"></div>

      <div className="container loginWrpr">
        <div className="row rounded-4 overflow-hidden boxShadow">
          <div className='loginBoxWrpr-sideSection col-md-6 p-0 d-flex-cc'>
            <div></div>
          </div>
          <div className='col-md-6 p-md-5 p-4 bg-white'>
            <h2 className="heading-2 mb-0 secondaryColor"> Login </h2>
            <DoctorsOTPloginForm setAuth={setAuth} setDoctorId={setDoctorId} />
            <Nav.Link href="/register" className='mt-4'> <span className='primaryColor'> Register or Sign Up </span> </Nav.Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
.pageBanner {
  height: 35vh;
}

${'' /* ----------------------- */}
.loginWrpr{
  width: 900px;
  .loginBoxWrpr-sideSection{
    height: auto;
    background-image: url('/images/loginSideImg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
${'' /* ----------------------- */}

@media (max-width: 768px) {
  .pageBanner {
    height: 0;
    background-position: left bottom;
  }
  .loginWrpr{
    width: auto;
    .loginBoxWrpr-sideSection{
      height: 250px;
    }
  }
}
`
