import React, { useEffect, useState } from "react";
import FARcard from "../../components/FARcard";
import styled from "styled-components";
import { RegisterRabiesEducation } from "../../components/Form";
import VaccineSchedulerSection from "./VaccineSchedulerSection";

export default function Section5() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile screens at 768px or less
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Add listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []);

  return (
    <Wrapper className="container p-md-0 p-4 py-0">
      <VaccineSchedulerSection />

        <div className="d-md-flex gap-4 py-4">
          <FARcard
            bgImg={isMobile ? "/images/s5doggy_mob.png" : "/images/s5doggy_v4.jpg"}
            bgClr={"#F15A24"}
            btnLink={"/bitten-by-a-dog"}
            title={"Bitten by a dog?"}
            para={"Here's what you should do.."}
            classPassed={"mb-3"}
          />

          <FARcard
            bgImg={isMobile ? "/images/who-are-at-risk-of-rabies_mob.jpg" : "/images/who-are-at-risk-of-rabies.jpg"}
            bgClr={"#B72133"}
            btnLink={"/who-are-at-risk"}
            title={"Who are at risk?"}
            para={"Any activity that increases the probability of a person coming in contact with saliva"}
          />
        </div>

        <div className="row gx-4">
          <div className="col-md-6">
            <FARcard
              bgImg={isMobile ? "/images/pledge_mob.jpg" : "/images/pledge.jpg"}
              bgClr={"#b4d626"}
              btnLink={"/oath"}
              title={"OATH"}
              para={"Let's take an oath to be fearless against rabies"}
              classPassed={"mb-3"}
            />
          </div>

          <div className="col-md-6">
            <div className="form-box bg-overlay-156">
              <h5 className="heading-5 primaryColor m-0">
                Register Your School for
              </h5>
              <h2 className="heading-2 secondaryColor">Rabies Education</h2>
          
              <RegisterRabiesEducation isMobile={isMobile} />
            </div>
          </div>
        </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 65px;
  .form-box {
    background-image: url("/images/bg_school_education.png");
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-color: #f1f1f1;
    padding: 35px;
    border-radius: 15px;
    height: 300px;
    overflow: hidden;
  }
  .bg-overlay-156{
    position: relative;
    z-index: 1;
  }
  .bg-overlay-156:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #f1f1f1, transparent);
    z-index: -1;
  }
  .form-fields {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    input {
      background-color: #fff;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 600px) {
    .form-box {
      background-size: 90%;
      padding: 30px;
      height: 700px;
    }
  }
`;
